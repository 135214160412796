import moment from 'moment';
import { getTime } from '@/models/Calendar';
import { useI18n } from 'vue-i18n';
import { PriceType } from '@/models/Interfaces';
import { PriceTypeCategory } from '@/models/Enums';
export default {
    methods: {
        isNumber: function(evt: any) {
            evt = evt ? evt : window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
            else return true;
        },
        isNumberFloat: function(evt: KeyboardEvent) {
            const charCode = evt.which ? evt.which : evt.keyCode;
            
            // Allow numbers (48-57), dot (46), and backspace (8)
            if (
                charCode > 31 &&
                charCode !== 46 &&
                (charCode < 48 || charCode > 57)
            ) {
                evt.preventDefault();
            }
        
            // Ensure only one decimal point is allowed
            if (charCode === 46 && (evt.target as HTMLInputElement).value.includes('.')) {
                evt.preventDefault();
            }
        },
        isNumberAllowNegative: function(event: any, allowNegative: boolean) {
            const char = String.fromCharCode(event.which || event.keyCode);
            const target = event.target;
            const value = target.value;
            const selectionStart = target.selectionStart;

            if (!allowNegative) {
                // Check that only numbers are entered
                if (!/[\d]/.test(char)) {
                    event.preventDefault();
                }
            } else {
                // Check that only numbers or minus signs are entered in the correct positions
                if (char === '-') {
                    if (selectionStart !== 0 || value.includes('-')) {
                        event.preventDefault();
                    }
                } else if (!/[\d]/.test(char)) {
                    event.preventDefault();
                }
            }
        },
        isDecimalNumber: function(evt: any) {
            evt = evt ? evt : window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) evt.preventDefault();
            else return true;
        },
        isNegativeNumber: function(evt: any) {
            evt = evt ? evt : window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45) evt.preventDefault();
            else return true;
        },
        isNegativeDecimalNumber: function(evt: any) {
            evt = evt ? evt : window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) evt.preventDefault();
            else return true;
        },
        filterKeyNonZero(event: KeyboardEvent) {
            if (event.key) {
                if (event.key.trim() === '' || isNaN(Number(event.key))) {
                    event.preventDefault();
                }
            }
            const el = event.target as HTMLInputElement;
            el.value = el.value.replace(/^0+/, '');
        },
        filterKeyOnlyNumber(event: KeyboardEvent) {
            if (event.key) {
                if (event.key.trim() === '' || isNaN(Number(event.key))) {
                    event.preventDefault();
                }
            }
            const el = event.target as HTMLInputElement;
            el.value = el.value.replace(/^0+/, '').replace(/\D+/g, '');
        },
        filterKeyOnlyDigits(event: KeyboardEvent) {
            if (event.key) {
                if (event.key.trim() === '' || /^[0-9]+$/.test(event.key.toString()) === false) {
                    event.preventDefault();
                }
            }
        },
        filterKeyOnlyNumbersDotComma(event: KeyboardEvent) {
            if (event.key) {
                if (event.key.trim() === '' || /^[0-9,.]+$/.test(event.key.toString()) === false) {
                    event.preventDefault();
                }
            }
        },
        filterKeyOnlyNumbersDot(event: KeyboardEvent) {
            if (event.key) {
                if (event.key.trim() === '' || /^[0-9.]+$/.test(event.key.toString()) === false) {
                    event.preventDefault();
                }
            }
        },
        filterKeyOnlyNumbersDotMinus(event: KeyboardEvent) {
            if (event.key) {
                const regex = /^-?\d{0,9}(?:\.\d{0,2})?$/;
                if (event.key.trim() === '' || regex.test(event.key.toString()) === false) {
                    event.preventDefault();
                }
            }
        },
        filterPastedOnlyNumbersDotMinus(event: any) {
            const value = event.clipboardData.getData('text/plain');
            const regex = /^-?\d{0,9}(?:\.\d{0,2})?$/;
            if (regex.test(value.toString()) === false) {
                event.preventDefault();
            }
        },
        filterPastedOnlyNumbersDotComma(event: any) {
            const value = event.clipboardData.getData('text/plain');

            if (/^[0-9,.]+$/.test(value.toString()) === false) {
                event.preventDefault();
            }
        },
        filterPastedOnlyNumbersDot(event: any) {
            const value = event.clipboardData.getData('text/plain');

            if (/^[0-9.]+$/.test(value.toString()) === false) {
                event.preventDefault();
            }
        },
        filterPastedOnlyNumber(event: any) {
            const value = event.clipboardData.getData('text/plain');

            if (/^\d+$/.test(value.toString()) === false) {
                event.preventDefault();
            }
        },
        filterPastedOnlyDigits(event: any) {
            const value = event.clipboardData.getData('text/plain');

            if (/^[0-9]+$/.test(value.toString()) === false) {
                event.preventDefault();
            }
        },
        formatAmount(item: number | string): string {
            if (!item) {
                return '0';
            }
            let result = (Math.round((Number(item) + Number.EPSILON) * 100) / 100).toFixed(2).toString();
            const arrRes = result.split('.');
            if (arrRes.length > 1) {
                const first = arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                result = first + '.' + arrRes[1];
            } else {
                result = arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                result += '.00';
            }
            return result;
        },
        formatPercentAmount(item: number | string): string {
            if (!item) {
                return '0';
            }
            let result = (Math.round((Number(item) + Number.EPSILON) * 100) / 100).toFixed(2).toString();
            const arrRes = result.split('.');
            if (arrRes.length > 1) {
                const first = arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                result = first + '.' + arrRes[1];
            } else {
                result = arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                result += '.00';
            }
            return result;
        },
        formatAmountNoDecimal(item: number | string): string {
            if (!item) {
                return '0';
            }
            const result = (Math.round((Number(item) + Number.EPSILON) * 100) / 100).toString();
            const arrRes = result.split('.');

            return arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        getDateToTitle(date: string) {
            const newDate = new Date(date);
            return moment(newDate).format('DD/MM/YYYY');
        },
        getHoursMinutesSlotDuration(time: string) {
            const startTime = moment.duration(time);
            const hours = startTime.hours();
            const minutes = startTime.minutes();
            if (hours == 0) {
                return useI18n().t('orders.duration-minutes', { minutes: minutes });
            }
            if (minutes == 0) {
                return useI18n().t('orders.duration-hours', { hours: hours });
            }
            return useI18n().t('orders.duration-hours-minutes', { hours: hours, minutes: minutes });
        },
        getHoursMinutes(totalMinutes: number): string {
            const minutes = (totalMinutes % 60).toString();
            const hours = Math.floor(totalMinutes / 60).toString();
            if (hours == '0') {
                return useI18n().t('orders.duration-minutes', { minutes: minutes });
            }
            if (minutes == '0') {
                return useI18n().t('orders.duration-hours', { hours: hours });
            }
            return useI18n().t('orders.duration-hours-minutes', { hours: hours, minutes: minutes });
        },
        getOrderProductStartDate(date: string) {
            return moment(date.replace('Z', '')).format('DD/MM/YYYY');
        },
        getMinutes(time: string) {
            const [hours, mins] = time.split(':');
            return parseInt(hours) * 60 + parseInt(mins);
        },
        formatTime(incomeData: string | null) {
            return moment(incomeData, 'HH:mm:ss').format('HH:mm');
        },
        getTime,
        formatLocalDateTime(incomeData: string | null, format = 'DD/MM/YYYY HH:mm') {
            if (!incomeData) return '';
            return moment
                .utc(incomeData)
                .local()
                .format(format);
        },
        formatLocalDateTimeIsrael(incomeData: string | null, format = 'DD/MM/YYYY HH:mm') {
            if (!incomeData) return '';
            return moment.utc(incomeData).format(format);
        },
        isEquivalent(a: any, b: any) {
            // Create arrays of property names
            const aProps = Object.getOwnPropertyNames(a);
            const bProps = Object.getOwnPropertyNames(b);

            // If number of properties is different,
            // objects are not equivalent
            if (aProps.length != bProps.length) {
                return false;
            }

            for (let i = 0; i < aProps.length; i++) {
                const propName = aProps[i];

                // If values of same property are not equal,
                // objects are not equivalent
                if (a[propName] !== b[propName]) {
                    return false;
                }
            }

            // If we made it this far, objects
            // are considered equivalent
            return true;
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.getElementById('main-content')?.scrollTo({ top: 0, behavior: 'smooth' });
        },
        activityStatuses(): { id: number; name: string }[] {
            return [
                {
                    id: 1,
                    name: useI18n().t('label.active')
                },
                {
                    id: 0,
                    name: useI18n().t('label.notactive')
                }
            ];
        },
        isGroupPrice(priceTypeId: string, priceTypes: PriceType[]) {
            if (!priceTypeId) {
                return false;
            }
            const price = priceTypes.find(x => x.id == priceTypeId);
            if (!price) {
                return false;
            }
            return (price.category & PriceTypeCategory.Group) == PriceTypeCategory.Group;
        }
    }
};
