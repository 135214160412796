import moment from 'moment';
import {
    ProductDocument,
    ProductVideo,
    ProductFAQ,
    ProductMoreInfo,
    FileTranslation,
    ProductImage,
    NotificationsRestrictions
} from '@/models/Interfaces';
import {
    WizzardStep,
    ProductRegistrationType,
    OrderBlockTypes,
    OrderBlockOption,
    PrintingType,
    ProductAutoOrderNotificationType
} from './Enums';
import { CalendarSlot } from './Calendar';

export class Product {
    id: string;
    idNoHash: number;
    providerId: string;
    name: string;
    active: boolean;
    shortDescription: string;
    fullDescription: string;
    fullDescriptionIndex: string;
    arrivalInstructions: string;
    parkingInstructions: string;
    hasSlot: boolean;
    hasPriceList: boolean;
    policy: string;
    longitude: number;
    latitude: number;
    address: string;
    regionId: string | null;
    recommended: boolean;
    mostPopular: boolean;
    calendarColor: string;
    productRegions: ProductRegion[];
    cityId: string | null;
    productTypeId: string | null;
    productTypeName: string | null;
    productTags: ProductTags[];
    productIncludedTags: ProductTags[];
    productExcludedTags: ProductTags[];
    productPrices: ProductPrice[];
    productImages?: ProductImage[];
    productDocuments?: ProductDocument[];
    productVideos?: ProductVideo[];
    productTranslations: Translation[];
    productSlots: CalendarSlot[];
    createdAt?: string;
    deletedAt?: string;
    updatedAt?: string;
    wizzardStep?: WizzardStep | null;
    averageDurationTime: number | null;
    receiveNotifications: boolean;
    pricePerPerson: boolean;
    blockCreatingNewOrdersBefore: boolean;
    orderBlockMinutes: number | null;
    productFaqs: ProductFAQ[];
    productInfos: ProductMoreInfo[];
    responsiblePersonName: string | null;
    responsiblePersonDescription: string | null;
    responsiblePersonPhotoUrl: string | null;
    extraInfo: string;
    onAir: boolean;
    useCashier: boolean;
    useLocals: boolean;
    useGroups: boolean;
    useB2cChannels: boolean;    
    printingType: PrintingType | null;
    useIndex: boolean;
    selectedOrderBlockType: OrderBlockTypes;
    selectedOrderBlockOption: OrderBlockOption;
    ticketImagePath: string | null;
    ticketImagePathSmall: string | null;
    orderLimitation: number | string | null;
    productsNotificationsRestrictions: NotificationsRestrictions | null;
    countryId: string | null;
    availableProductPriceTypes: string[];
    allowAssignEmployees: boolean;
    receiveAutoOrderNotificationMessages: boolean;
    productAutoOrderNotificationMessages: ProductAutoOrderNotification[];
    promotionBannerText: string | null;
    externalProviderUrl: string | null;
    useDirectChannels: boolean;
    externalCodes_TotalQuantity = 0;
    externalCodes_UsedQuantity = 0;
    externalCodes_NotUsedQuantity = 0;
    externalCodesMode = 1;

    constructor() {
        this.id = '';
        this.idNoHash = 0;
        this.providerId = '';
        this.name = '';
        this.active = true;
        this.shortDescription = '';
        this.fullDescription = '';
        this.fullDescriptionIndex = '';
        this.arrivalInstructions = '';
        this.parkingInstructions = '';
        this.hasSlot = false;
        this.hasPriceList = false;
        this.policy = '';
        this.longitude = 0;
        this.latitude = 0;
        this.address = '';
        this.regionId = null;
        this.recommended = false;
        this.mostPopular = false;
        this.calendarColor = '';
        this.productRegions = [];
        this.cityId = null;
        this.productTypeId = null;
        this.productTypeName = null;
        this.productTags = [];
        this.productIncludedTags = [];
        this.productExcludedTags = [];
        this.productPrices = [];
        this.productTranslations = [];
        this.productSlots = [];
        this.productVideos = [];
        this.createdAt = undefined;
        this.deletedAt = undefined;
        this.updatedAt = undefined;
        this.wizzardStep = null;
        this.averageDurationTime = null;
        this.receiveNotifications = true;
        this.pricePerPerson = true;
        this.blockCreatingNewOrdersBefore = false;
        this.orderBlockMinutes = 0;
        this.productFaqs = [];
        this.productInfos = [];
        this.responsiblePersonName = null;
        this.responsiblePersonDescription = null;
        this.responsiblePersonPhotoUrl = null;
        this.extraInfo = '';
        this.onAir = false;
        this.useCashier = false;
        this.useLocals = true;
        this.useGroups = false;
        this.useB2cChannels = true;
        this.useIndex = false;
        this.selectedOrderBlockType = OrderBlockTypes.minutes;
        this.selectedOrderBlockOption = OrderBlockOption.allow;
        this.printingType = null;
        this.ticketImagePath = null;
        this.ticketImagePathSmall = null;
        this.orderLimitation = null;
        this.productsNotificationsRestrictions = null;
        this.countryId = null;
        this.availableProductPriceTypes = [];
        this.allowAssignEmployees = false;
        this.receiveAutoOrderNotificationMessages = false;
        this.productAutoOrderNotificationMessages = [];
        this.promotionBannerText = null;
        this.externalProviderUrl = null;
        this.useDirectChannels = true;
    }
}

export class ProductAutoOrderNotification {
    id = '';
    intervalMinutes: number | null = null;
    intervalType: ProductAutoOrderNotificationType | null = null;
    intervalTimeType: OrderBlockTypes | null = null;
    messageTemplateId = 0;
}
export class ProductListItem {
    id = '';
    name = '';
    color = '';
    registrationType = ProductRegistrationType.RegistrationIsRequired;
    extraInfo = '';
    orderBlockMinutes = 0;
    availableProductPriceTypes: string[] = [];
}
export class Language {
    id: string;
    name: string;
    twoLetterIsoLanguageName: string;

    constructor() {
        this.id = '';
        this.name = '';
        this.twoLetterIsoLanguageName = '';
    }
}
export class Translation {
    arrivalInstructions: string;
    parkingInstructions: string;
    fullDescription: string;
    fullDescriptionIndex: string;
    language: Language;
    name: string;
    shortDescription: string;
    responsiblePersonDescription: string;
    policy: string;
    extraInfo: string;

    constructor() {
        this.arrivalInstructions = '';
        this.parkingInstructions = '';
        this.fullDescription = '';
        this.fullDescriptionIndex = '';
        this.language = new Language();
        this.name = '';
        this.shortDescription = '';
        this.responsiblePersonDescription = '';
        this.policy = '';
        this.extraInfo = '';
    }
}
export class ProductRegion {
    id: string;
    productId: string;
    regionId: string;
    regionName?: string;

    constructor() {
        this.id = '';
        this.productId = '';
        this.regionId = '';
    }
}
export class PriceType {
    id: string;
    text: string;

    constructor() {
        this.id = '';
        this.text = '';
    }
}
export class ProductCustomerType {
    id: string | null;
    productId: string;
    name: string;

    constructor() {
        this.id = '';
        this.productId = '';
        this.name = '';
    }
}
export class ProductPriceLine {
    amount: number | null;
    currencyId: string;
    id: string;
    currencySymbol: string;
    isOfficial: boolean;
    productCustomerType?: ProductCustomerType;
    productCustomerTypeId: string | null;
    productPriceId: string;
    quantity: number | null;
    priority: number | null;
    barCode: string | null;

    constructor() {
        this.amount = null;
        this.currencyId = '';
        this.id = '';
        this.currencySymbol = '';
        this.isOfficial = false;
        this.productCustomerTypeId = '';
        this.productPriceId = '';
        this.quantity = null;
        this.priority = null;
        this.barCode = null;
    }
}
export class ProductPrice {
    autoConfirm: boolean;
    priceType?: PriceType;
    priceTypeId?: string;
    currencyId: string;
    fromDate: string;
    id: string;
    officialPrice: number;
    productId: string;
    productPriceLines: ProductPriceLine[];
    remarks: string;
    title: string;
    toDate: string;
    deletedAt: string | null;
    fromWizzard: boolean;
    channelId: string;
    isValid: boolean;
    couponId: string | null;
    couponText: string | null;
    couponLink: string | null;
    businessClientId: string | null;
    businessClientTypeId: string | null;

    constructor() {
        this.autoConfirm = false;
        this.currencyId = '';
        this.fromDate = moment()
            .toDate()
            .toDateString();
        this.toDate = moment(`${moment().year() + 1}-12-31`)
            .toDate()
            .toDateString();
        this.id = '';
        this.officialPrice = 0;
        this.productId = '';
        this.productPriceLines = [];
        this.remarks = '';
        this.title = '';
        this.deletedAt = null;
        this.fromWizzard = false;
        this.channelId = '';
        this.isValid = false;
        this.couponId = null;
        this.couponText = null;
        this.couponLink = null;
        this.businessClientId = null;
        this.businessClientTypeId = null;
    }
}
export class ProductTags {
    id: string;
    productId: string;
    subTagId: string;
    parentTagId?: string;
    subTagName?: string;

    constructor() {
        this.id = '';
        this.productId = '';
        this.subTagId = '';
    }
}
export class ProductAllTags {
    productTags: ProductTags[];
    productExcludedTags: ProductTags[];
    productIncludedTags: ProductTags[];
    constructor() {
        this.productTags = [];
        this.productExcludedTags = [];
        this.productIncludedTags = [];
    }
}
export class ProductTranslationItem {
    languageId: string;
    newTranslation: string;
    constructor() {
        this.languageId = '';
        this.newTranslation = '';
    }
}
export class ProductFilter {
    productName: string;
    typeId: string;
    status?: boolean | number | null;
    keyword: string;

    constructor() {
        this.productName = '';
        this.typeId = '';
        this.status = null;
        this.keyword = '';
    }
}
export class OrdersNotifications {
    rqOrdersCount: number;
    unreadMessagesCount: number;
    constructor() {
        this.rqOrdersCount = 0;
        this.unreadMessagesCount = 0;
    }
}

export class ProductAlert {
    sourceId: string;
    message: string;
    productName: string;
    alertType: number;

    constructor() {
        this.sourceId = '';
        this.message = '';
        this.productName = '';
        this.alertType = 0;
    }
}
