import { Dictionary, isNumber } from 'lodash';
import { useI18n } from 'vue-i18n';

export enum StepNumber {
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4
}
export enum WeekNames {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}
export enum DayOfWeekFlag {
    MO = 1,
    TU = 2,
    WE = 4,
    TH = 8,
    FR = 16,
    SA = 32,
    SU = 64
}
export const DayOfWeekToIso: Dictionary<number> = {
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
    SU: 7
};
export const DayOfWeekKendo = {
    Sunday: 'SU',
    Monday: 'MO',
    Tuesday: 'TU',
    Wednesday: 'WE',
    Thursday: 'TH',
    Friday: 'FR',
    Saturday: 'SA'
};
export const DayOfWeek = {
    0: 'SU',
    1: 'MO',
    2: 'TU',
    3: 'WE',
    4: 'TH',
    5: 'FR',
    6: 'SA'
};
export enum OrderStatus {
    OK = 1,
    RQ = 2,
    CN = 3,
    ERR = 4
}
export enum OrderStatusColors {
    '#79D217' = 1,
    '#FD695A' = 2,
    '#625C82' = 3,
    '' = 4
}

export enum AccountingDocumentStatus {
    None = 0,
    Approved = 1,
    Canceled = 2,
    Request = 3,
    Paid = 4
}

export enum PaymentType {
    Cash = 1,
    Check = 2,
    CreditCard = 4,
    BankTransfer = 8
}

export enum CouponType {
    Regular = 1,
    NextPurchase = 2,
    BusinessClientAgreement = 3
}

export enum CouponDiscountType {
    Discount = 1,
    TicketFixedUnitPrice = 2,
    NoDiscount = 3
}

export enum CouponWhoFinances {
    NoFinance = 1,
    EntireOrder = 2,
    Discount = 3
}

export enum VatType {
    IncVat = 0,
    NoVat = 1
}

export enum ExternalAccountingSystem {
    Hashavshevet = 1,
    Eshkol = 2
}

export enum CardType {
    Visa = 1,
    Mastercard = 2,
    Isracard = 4,
    Eurocard = 8,
    Diners = 16,
    AmericanExpress = 32,
    LeumiCard = 64,
    VisaAlpha = 128
}

export enum PaymentStatus {
    None = 0,
    Ok = 1,
    Error = 2,
    Cancel = 3
}

export enum CreditCardPaymentType {
    Regular = 1,
    Credit = 2,
    Payments = 3
}

export enum DocumentType {
    Invoice = 1,
    Receipt = 2,
    OrderSummary = 3,
    Custom = 4,
    InvoiceReceipt = 5,
    Perfoma = 6
}
export enum DocumentTypeForFilter {
    Invoice = 1,
    Receipt = 2,
    CreditInvoice = 3
}

export enum NotificationType {
    NewOrder = 1,
    NewChatMsg = 2,
    NewReceipt = 3,
    NewInvoice = 4,
    OrderSlotChanged = 5,
    OrderStatusChanged = 6,
    NewOrderQuote = 13,
    NewIndexChatMsg = 14,
    NewInvoiceReceipt = 15
}

export enum InvoiceType {
    Debit = 1,
    Credit = 2,
    Perfoma = 3
}

export enum WizzardStep {
    None = 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Step1_1 = 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Step1_2 = 2,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Step1_3 = 3,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Step1_4 = 4,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Step2_1 = 5
}

export enum ProductAlertType {
    None,
    SlotNotFound,
    ProductPriceNotFound,
    ImageNotFound,
    SlotLengthLess30,
    ProductPriceLengthLess30,
    OnAir
}

export enum ArrivalConfirmation {
    None,
    NotArrived,
    ArrivedPartially,
    Arrived
}

export enum ProductInfoType {
    General = 1,
    Covid19 = 2,
    Custom = 3
}

export enum CouponSystem {
    Multipass = 1,
    Dts = 2,
    Icmega = 3,
    Tmura = 4
}

export enum PriceTypeCategory {
    Local = 1,
    Tourist = 2,
    Individual = 4,
    Group = 8,
    Club = 16
}

export enum ProductRegistrationType {
    RegistrationIsRequired = 1,
    RegistrationIsNotRequired = 2,
    NotForSale = 3
}

export enum OrderBlockTypes {
    minutes = 0,
    hours = 1,
    days = 2
}

export enum OrderBlockOption {
    before = 1,
    after = 2,
    allow = 3
}

export enum PrintingType {
    SingleUnitTicket = 1,
    MultipleUnitTicket = 2,
    OrderSummaryDocument = 3
}

export enum CouponLimitationType {
    Ticket = 1,
    TotalAmount = 2
}

export function toCamelCase(str: string): string {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => (index === 0 ? match.toLowerCase() : match.toUpperCase()))
        .replace(/\s+/g, '');
}

export function ToOptions(e: any, prefix = '', useCamelCase = false) {
    return Object.entries(e)
        .filter(x => typeof x[0] === 'string' && isNumber(x[1]))
        .map(x => {
            return {
                id: x[1],
                name: useCamelCase ? useI18n().t(prefix + toCamelCase(x[0])) : useI18n().t(prefix + x[0])
            };
        });
}

export enum ComparisonOperator {
    LessThan = 0,
    BiggerThan = 1,
    EqualTo = 2
}

export enum ReceiptPaymentSteps {
    SelectType = 1,
    CreditCard = 2,
    Cash = 3,
    Check = 4,
    BankTransfer = 5
}

export enum ExportRequestStatus {
    Canceled,
    Ok
}

export enum ExternalAccountingExportDocumentType {
    Receipt = 1,
    Invoice = 2
}
export enum ProductDetailSections {
    Details = 1,
    FAQ = 2,
    Address = 3,
    MoreInfo = 4,
    TimeAndDuration = 5,
    PhotosAndVideo = 6,
    Documents = 7,
    Pricing = 8,
    Notifications = 9,
    General = 10,
    ActivityOwner = 11,
    SellingSettings = 12
}
export enum MessageQueueStatus {
    Initial = 1,
    InProgress = 2,
    Fail = 3,
    Done = 4
}
export enum MessageQueueStatuColors {
    '#808080' = 1, //Initial
    '#e67e22' = 2, //InProgress
    '#e74c3c' = 3, //Fail
    '#79D217' = 4 // Done
}
export enum ProductAutoOrderNotificationType {
    BeforeSlotTime = 1,
    AfterSlotTime = 2
}
export enum QueueMessageSource {
    Orders = 1,
    Slot = 2,
    Automation = 3
}

export enum OrderSearchType {
    OrderNumber = 1,
    Ticket = 2
}

export enum ExportOrdersType {
    Orders = 1,
    OrderProducts = 2,
    OrderProductsCustomerTypes = 3
}
export enum BulkInvoiceJobStatus {
    Started = 1,
    Finished = 2,
    Failed = 3,
    CreateCSVFail = 500,
    CreatePdfFail = 501
}
export enum BulkInvoiceJobStatusColors {
    '#f6ae28' = 1,
    '#79d217' = 2,
    '#ff2200' = 3,
    '#Fd695a' = 500,
    '#fd695a' = 501
}

export enum SubCouponStatus {
    Created = 1,
    Reserved = 2,
    Used = 3
}
export enum CrmCustomerHistoryTypes {
    Sms = 1,
    Email = 2,
    WhatsApp = 3,
    Lead = 4,
    Quote = 5,
    Orders = 6
}
export enum SnoozeTypes {
    FiveMinutes = 1,
    OneHour = 2,
    OneDay = 3,
    Custom = 4
}
export enum SnoozePeriods {
    minutes = 1,
    hours = 2,
    days = 3
}
export enum CrmCustomerSaveChanges {
    FirstName = 1 << 0,
    LastName = 1 << 1,
    Email = 1 << 2,
    BusinessClient = 1 << 3,
    Mobile = 1 << 4,
    Birthdate = 1 << 5,
    Remarks = 1 << 6,

    AllExcludeRemarks = FirstName | LastName | Email | BusinessClient | Mobile | Birthdate,
    All = FirstName | LastName | Email | BusinessClient | Mobile | Birthdate | Remarks
}
export enum ReminderSaveChanges {
    AlertDateTime = 1 << 0,
    Name = 1 << 1,
    Active = 1 << 2,
    OnlyForMe = 1 << 3,

    All = AlertDateTime | Name | Active | OnlyForMe
}
export enum CrmCustomerActivitySaveChanges {
    DateTime = 1 << 0,
    Type = 1 << 1,
    Location = 1 << 2,
    Subject = 1 << 3,
    Remarks = 1 << 4,
    Active = 1 << 5,

    All = DateTime | Type | Location | Subject | Remarks | Active
}

export enum CrmCustomerMessageType {
    SYSTEM = 1,
    USER = 2,
    CUSTOMER = 3,
    LEAD = 4
}

export enum CrmCustomerDetailTabs {
    Activities = 1,
    Orders = 2,
    Leads = 3,
    Quotes = 4
}
export enum CrmCustomerActionTypes {
    Reminder = 1,
    Neworder = 2,
    DeleteCustomer = 3,
    AddActivity = 4,
    CreateLead = 5
}
export enum LeadStatus {
    New = 0,
    InProgress = 1,
    Done = 2,
    NotActive = 3
}
export enum LeadStatusColors {
    '#7AD318' = 0,
    '#F98332' = 1,
    '#00BFDB' = 2,
    '#B4B4B4' = 3
}
export enum CrmCustomerLeadSettingActionTypes {
    ManageTypes = 1,
    ManageSources = 2,
    ManageReply = 3
}
export enum CrmCustomerLeadSaveChanges {
    LeadType = 1 << 1,
    LeadSource = 1 << 2,
    Description = 1 << 3,
    NumberOfGuests = 1 << 4,
    Active = 1 << 5,
    Remarks = 1 << 6,
    Status = 1 << 7,

    NewEntity = LeadType | LeadSource | Description | NumberOfGuests | Active
}
export enum QuickReplyMode {
    Manage = 1,
    Select = 2
}
export enum CrmCustomerReminderFilter {
    All = 1,
    Active = 2
}

export enum PaginationDirection {
    Forward = 0,
    Backward = 1
}
