import { BaseApi, ApiResult } from '@/services/BaseApi';
import * as ApiInterfaces from '@/models/Interfaces';
import {
    Product,
    ProductCustomerType,
    ProductTranslationItem,
    ProductPrice,
    ProductFilter,
    OrdersNotifications,
    ProductAlert
} from '@/models/Product';
import { SaveProductAddressRequest, SaveProviderAddressRequest } from '@/models/RequestInterfaces';
import { GetNotificationsResponse, NewUserRegistrationResponse, ConfirmEmailResponse } from '@/models/ResponseInterfaces';
import { Customer, OrderProductDetails, OrdersFilter } from '@/models/Order';
import { AssignedEmployeesResponse, CheckAssignEmployeeResponse, ExportEmployeesSlots, SlotsWithNoEmployeeFilter } from '@/models/Employee';
import { CancelReceiptResponse, DocumentsFilter } from '@/models/Document';
import {
    CreditCardReceipt,
    CashReceipt,
    BankTransferReceipt,
    CheckReceipt,
    Invoice,
    BusinessInvoice,
    Receipt,
    InvoiceReceipt
} from '@/models/Accounting';
import { CalendarEvent, CalendarSlot, CalendarStopSale } from '@/models/Calendar';
import { RegistrationForm, RegistrationDetails, ResetPasswordForm } from '@/models/Models';
import { AccountingInfo, Coupon, CouponFilter, ProviderSettingsData, BulkInvoiceHistory } from '@/models/Provider';
import {
    BusinessClientsFilter,
    BusinessClient,
    BusinessClientPhotoInstruction,
    BusinessOrderProductsFilter,
    BusinessAccountingFilter,
    BusinessInvoiceCustomersFilter
} from '@/models/BusinessClients';
import { ChatFilter, ChatMessage } from '@/models/ChatQuotes';
import {
    AgentDetails,
    GetTagsProducts,
    IdName,
    IndexStatisticsResult,
    MessageTemplateFilter,
    EmailManagementFilter,
    SmsManagementFilter,
    PaymentsAccountTransactionsFilter,
    GetCouponsResponse,
    SubCouponsFilterResult,
    SendContractFormRequest
} from '@/models/Interfaces';
import { ExportRequest } from '@/models/Export';
import NotificationsRestrictions from '@/components/NotificationsRestrictions.vue';
import { Employee, EmployeeFilter } from '@/models/Employee';
import {
    MessageTemplate,
    MessageTemplateResource,
    SmsManagement,
    EmailManagement,
    GetMessageTemplatesResponse,
    GetSmsMessagesResponse,
    GetEmailMessagesResponse,
    MessageTemplateAttachment,
    GetMessageTemplateLightListResponse
} from '@/models/MessageTemplate';
import {
    DocumentType,
    ExportOrdersType,
    OrderSearchType,
    SubCouponStatus,
    CrmCustomerReminderFilter,
    PaginationDirection
} from '@/models/Enums';
import { SubCouponsFilter } from '@/models/Coupons';
import {
    CrmCustomerListFilter,
    CrmCustomerReminderSaveRequest,
    QuickReplyMessageEditModel,
    QuickReplyMessageViewModel,
    CrmCustomerLeadListFilter
} from '@/models/CrmCustomers';

export function getTimezone() {
    return -new Date().getTimezoneOffset();
}
class Api extends BaseApi {
    public async init(): Promise<ApiResult<ApiInterfaces.InitResponse>> {
        return this.get<ApiInterfaces.InitResponse>('api/init');
    }
    public async changeLanguage(lang: string): Promise<ApiResult<ApiInterfaces.ChangeLanguage>> {
        return this.post<ApiInterfaces.ChangeLanguage>('api/changelanguage', JSON.stringify({ lang: lang }));
    }

    public async getProviderNotifications(lastId: string, fromId: string): Promise<ApiResult<GetNotificationsResponse>> {
        return this.post<GetNotificationsResponse>('api/notifications', {
            lastId: lastId ? lastId : null,
            fromId: fromId ? fromId : null
        });
    }

    public async notificationMarkAsReaded(id: string, markAll: boolean): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/notification-mark-as-readed', { NotificationId: id ? id : null, MarkAll: markAll });
    }

    public async login(Username: string, Password: string): Promise<ApiResult<ApiInterfaces.LoginResponse>> {
        return this.post<ApiInterfaces.LoginResponse>('api/authenticate', JSON.stringify({ Username, Password }));
    }

    public async logout(): Promise<ApiResult<void>> {
        return this.get<void>('api/logout');
    }

    public async getProduct(id: string): Promise<ApiResult<Product>> {
        return this.get<Product>('api/product/' + id);
    }

    public async saveProduct(product: Product): Promise<ApiResult<ApiInterfaces.SaveProductResponse>> {
        return this.post<ApiInterfaces.SaveProductResponse>('api/product', JSON.stringify(product));
    }

    public async updateProductSlotOrderBlockMin(productId: string) {
        return this.post<ApiInterfaces.ApiResponseBase[]>(`api/product/${productId}/updateSlotsOrderBlockMin`, JSON.stringify({}));
    }

    public async getPriceTypeProducts(
        priceTypeId: string,
        businessClientId: number | null
    ): Promise<ApiResult<ApiInterfaces.OnSelectProduct[]>> {
        return this.get<ApiInterfaces.OnSelectProduct[]>('api/order/products/price-type', {
            params: { priceTypeId, businessClientId: businessClientId }
        });
    }

    public async getAllTags(): Promise<ApiResult<ApiInterfaces.Tag[]>> {
        return this.get<ApiInterfaces.Tag[]>('api/tags');
    }

    public async createTag(name: string): Promise<ApiResult<ApiInterfaces.Tag>> {
        return this.post<ApiInterfaces.Tag>('api/tag', JSON.stringify(name));
    }

    public async editTag(id: string, name: string): Promise<ApiResult<ApiInterfaces.Tag>> {
        return this.put<ApiInterfaces.Tag>('api/tag/' + id, JSON.stringify(name));
    }

    public async deleteTag(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/tag/' + id);
    }

    public async createSubTag(tagId: string, subTagName: string): Promise<ApiResult<ApiInterfaces.SubTag>> {
        return this.post<ApiInterfaces.SubTag>('api/subtag/' + tagId, JSON.stringify(subTagName));
    }

    public async editSubTag(id: string, subTagName: string): Promise<ApiResult<ApiInterfaces.SubTag>> {
        return this.put<ApiInterfaces.SubTag>('api/subtag/' + id, JSON.stringify(subTagName));
    }

    public async deleteSubTag(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/subtag/' + id);
    }
    public async uploadFile(
        file: FormData,
        projectId: string,
        fromWizzard = false
    ): Promise<ApiResult<ApiInterfaces.SaveProductImageResponse>> {
        return this.postFormData<ApiInterfaces.SaveProductImageResponse>(`api/product/${projectId}/file/${fromWizzard}`, file);
    }
    public async uploadTicketImage(file: FormData, projectId: string): Promise<ApiResult<ApiInterfaces.TicketImageResponse>> {
        return this.postFormData<ApiInterfaces.TicketImageResponse>(`api/product-ticket-image/${projectId}`, file);
    }
    public async uploadBusinessClientCompanyLogo(file: FormData, businessClientId: number): Promise<ApiResult<string>> {
        return this.postFormData<string>(`api/businessClient/companyLogo/${businessClientId}`, file);
    }
    public async deleteInstructionPhoto(businessClientId: number, photoId: string) {
        return this.post<ApiInterfaces.FileTranslation[]>(
            `api/businessClient/photoInstruction/delete`,
            JSON.stringify({ businessClientId, photoId })
        );
    }

    public async uploadBusinessClientPhotoInstruction(
        file: FormData,
        businessClientId: number
    ): Promise<ApiResult<BusinessClientPhotoInstruction>> {
        return this.postFormData<BusinessClientPhotoInstruction>(`api/businessClient/photoInstruction/${businessClientId}`, file);
    }
    public async uploadResposiblePersonFile(
        file: FormData,
        projectId: string
    ): Promise<ApiResult<ApiInterfaces.SaveResponsiblePersonImageResponse>> {
        return this.postFormData<ApiInterfaces.SaveResponsiblePersonImageResponse>(
            `api/product/${projectId}/upload-responsible-person-image`,
            file
        );
    }
    public async deleteResposiblePersonImage(productId: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>(`api/product/${productId}/delete-responsible-person-image`);
    }
    public async uploadDocument(file: FormData, projectId: string): Promise<ApiResult<ApiInterfaces.ProductDocument>> {
        return this.postFormData<ApiInterfaces.ProductDocument>(`api/product/${projectId}/document`, file);
    }
    public async removeDocument(id: string): Promise<ApiResult<ApiInterfaces.ProductDocument>> {
        return this.delete<ApiInterfaces.ProductDocument>(`api/product/delete-document`, { params: { documentId: id } });
    }
    public async getProductTranslations(projectId: string): Promise<ApiResult<ApiInterfaces.ProductTranslation[]>> {
        return this.get<ApiInterfaces.ProductTranslation[]>(`api/product/${projectId}/translations`);
    }
    public async saveProductImageTranslations(imageId: string, newLocalization: ApiInterfaces.FileTranslation[]) {
        return this.post<ApiInterfaces.FileTranslation[]>(`api/product/image/${imageId}/saveTranslation`, JSON.stringify(newLocalization));
    }
    public async saveProductDocumentTranslations(documentId: string, newLocalization: ApiInterfaces.FileTranslation[]) {
        return this.post<ApiInterfaces.FileTranslation[]>(
            `api/product/document/${documentId}/saveTranslation`,
            JSON.stringify(newLocalization)
        );
    }
    public async saveNewProductNameTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveNameTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveNewProductShortDescriptionTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveShortDescriptionTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveNewProductLongDescriptionTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveLongDescriptionTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveNewProductLongDescriptionIndexTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveLongDescriptionIndexTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveupdateProductArrivalIstructionsTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/arrivalIstructionsTranslation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveupdateProductParkingIstructionsTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/parkingIstructionsTranslation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveupdateProductResponsiblePersonDescriptionTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveResponsiblePersonDescriptionTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveupdateProductPolicyTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveProductPolicyTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async saveupdateProductExtraInfoTranslation(productId: string, Translations: ProductTranslationItem[]) {
        return this.post<ApiInterfaces.ProductTranslation[]>(
            `api/product/${productId}/saveProductExtraInfoTranlation`,
            JSON.stringify({ productId, Translations })
        );
    }
    public async deleteActivity(id: string): Promise<ApiResult<Product>> {
        return this.delete<Product>(`api/product/${id}`);
    }
    public async getProductCustomerTypes(productId: string): Promise<ApiResult<ProductCustomerType[]>> {
        return this.get<ProductCustomerType[]>(`api/customer-types/${productId}`);
    }
    public async getCouponOptions(
        productsIds: string[] | null,
        fromDate: string,
        toDate: string,
        priceTypesIds: string[] | null,
        customerTypesIds: string[] | null
    ): Promise<ApiResult<ApiInterfaces.CouponOptions>> {
        return this.post<ApiInterfaces.CouponOptions>(
            `api/coupon-options`,
            JSON.stringify({ productsIds, fromDate, toDate, priceTypesIds, customerTypesIds })
        );
    }
    public async GetProductSlots(
        priceTypeId: string,
        productId: string,
        currencyId: string,
        date: string,
        customerId: string
    ): Promise<ApiResult<ApiInterfaces.GetProductSlotsResponse>> {
        return this.get<ApiInterfaces.GetProductSlotsResponse>('api/order/products/slots', {
            params: { PriceTypeId: priceTypeId, ProductId: productId, Date: date, CurrencyId: currencyId, CustomerId: customerId }
        });
    }
    public async CalculateProductCustomers(
        request: ApiInterfaces.CalculateProductCustomersRequest
    ): Promise<ApiResult<ApiInterfaces.CalculateProductCustomersResponse>> {
        request.customers?.forEach(customer => {
            customer.date = null;
        });

        return this.post<ApiInterfaces.CalculateProductCustomersResponse>('api/order/products/calculate', JSON.stringify(request));
    }
    public async CalculateOrder(
        channelId: string,
        priceTypeId: string,
        discountAmount: number | null,
        discountPercent: number | null,
        currency: string,
        products: OrderProductDetails[],
        businessClientId?: number | null,
    ): Promise<ApiResult<ApiInterfaces.CalculateOrderResponse>> {
        products.forEach(p => `${p.date} ${p.slot?.startTime}`);
        const date = {
            PriceTypeId: priceTypeId,
            DiscountAmount: discountAmount,
            discountPercent,
            CurrencyId: currency,
            ChannelId: channelId,
            Products: products,
            businessClientId
        };
        return this.post<ApiInterfaces.CalculateOrderResponse>('api/order/calculate', JSON.stringify(date));
    }

    public async GetProductCustomers(
        priceTypeId: string,
        productId: string,
        date: string,
        businessClientId: number | null
    ): Promise<ApiResult<ApiInterfaces.GetProductCustomersResponse>> {
        return this.get<ApiInterfaces.GetProductCustomersResponse>('api/order/products/customer-types', {
            params: { PriceTypeId: priceTypeId, ProductId: productId, Date: date, businessClientId }
        });
    }

    public async SaveOrder(data: ApiInterfaces.SaveOrderObject): Promise<ApiResult<ApiInterfaces.SaveOrderResponse>> {
        return this.post<ApiInterfaces.SaveOrderResponse>('api/order/create', JSON.stringify(data));
    }
    public async updateOrCreateCustomerType(newCustomer: ProductCustomerType): Promise<ApiResult<ProductCustomerType>> {
        return this.post<ProductCustomerType>('api/product/updateCustomerType', JSON.stringify(newCustomer));
    }
    public async RemoveCustomer(customerId: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>(`api/product/deleteCustomerType/`, { params: { customerId: customerId } });
    }

    public async GetOrder(orderId: string): Promise<ApiResult<ApiInterfaces.GetOrderResponse>> {
        return this.get<ApiInterfaces.GetOrderResponse>('api/order/get', { params: { id: orderId } });
    }

    public async GetOrderLink(orderId: string) {
        return this.get<ApiInterfaces.GetOrderLinkResponse>('api/order/getlink', { params: { id: orderId } });
    }

    public async EditOrder(
        orderId: string,
        customer: Customer,
        businessClientId: number | null
    ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/order/edit/customer/business-client', {
            OrderId: orderId,
            Customer: customer,
            businessClientId
        });
    }

    public async GetOrderMessages(orderId: string, type: string, fromId = '', showOld = false) {
        return this.post<ApiInterfaces.GetOrderMessagesResponse>(
            'api/order/messages/get',
            JSON.stringify({ orderId, type, fromId, showOld })
        );
    }

    public async SendOrderMessage(orderId: string, message: string) {
        return this.post<ApiInterfaces.ApiResponseBase>('api/order/messages/send', JSON.stringify({ orderId, message }));
    }

    public async productHasOrders(productId: string): Promise<ApiResult<boolean>> {
        return this.get<boolean>('api/product/has-orders', { params: { productId } });
    }

    public async getMyOrders(filter: OrdersFilter): Promise<ApiResult<ApiInterfaces.GetMyOrderResponse>> {
        return this.post<ApiInterfaces.GetMyOrderResponse>('api/orders', JSON.stringify({ filter }));
    }
    public async qtyReportGet(filter: OrdersFilter): Promise<ApiResult<ApiInterfaces.QtyReportGetResponse>> {
        return this.post<ApiInterfaces.QtyReportGetResponse>('api/orders/qty-report', JSON.stringify({ filter }));
    }

    public async findOrderBy(req: string, searchBy: OrderSearchType): Promise<ApiResult<ApiInterfaces.GetOrdersResponse>> {
        return this.get<ApiInterfaces.GetOrdersResponse>('api/order/find-by', { params: { req, searchBy } });
    }

    public async findOrderByScan(req: string): Promise<ApiResult<ApiInterfaces.FindOrdersByScanResponse>> {
        return this.get<ApiInterfaces.FindOrdersByScanResponse>('api/order/find-by-scan', { params: { req } });
    }

    public async createCreditCardReceipt(receipt: CreditCardReceipt): Promise<ApiResult<CreditCardReceipt>> {
        return this.post<CreditCardReceipt>('api/create-credit-card-receipt', JSON.stringify(receipt));
    }

    public async createCashReceipt(receipt: CashReceipt): Promise<ApiResult<CashReceipt>> {
        return this.post<CashReceipt>('api/create-cash-receipt', JSON.stringify(receipt));
    }

    public async createBankTransferReceipt(receipt: BankTransferReceipt): Promise<ApiResult<BankTransferReceipt>> {
        return this.post<BankTransferReceipt>('api/create-bank-transfer-receipt', JSON.stringify(receipt));
    }

    public async createCheckReceipt(receipt: CheckReceipt): Promise<ApiResult<CheckReceipt>> {
        return this.post<CheckReceipt>('api/create-check-receipt', JSON.stringify(receipt));
    }

    public async createInvoice(invoice: BusinessInvoice): Promise<ApiResult<Invoice>> {
        return this.post<Invoice>('api/create-invoice', JSON.stringify(invoice));
    }

    //Reports

    public async GetReportData(filter: { productId: string | null; fromDate: string | null; toDate: string | null }) {
        return this.get<ApiInterfaces.DashboardResult>('api/report/dashboad-data', { params: filter });
    }
    public async GetIndexStatistics(filter: { productId: string | null; fromDate: string | null; toDate: string | null }) {
        return this.get<ApiInterfaces.IndexStatisticsResult>('api/index-statistics', { params: filter });
    }
    // Reports END

    public async SetOrderStatus(id: string, status: string, confirmed: boolean): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.get<ApiInterfaces.ApiResponseBase>('api/order/edit/status/' + status, { params: { id, confirmed } });
    }
    public async CreateOrderSummary(id: string, viewNow: boolean) {
        return this.get<ApiInterfaces.TaskResponse>('api/order/create-summary', { params: { id, viewNow, timezone: getTimezone() } });
    }
    public async WaitOrderSummary(taskId: string) {
        return this.get<ApiInterfaces.AwaitTaskResponse>('api/order/wait-summary', { params: { id: taskId } });
    }
    public async getProviderSetting(key: string): Promise<ApiResult<string>> {
        return this.get<string>('api/provider-setting/' + key);
    }
    public async getCalendarV3(
        productId: string | null = null,
        slotId: string | null = null,
        removePastSlots: boolean,
        changeDate: boolean
    ): Promise<ApiResult<ApiInterfaces.CalendarSlotResponse>> {
        return this.get<ApiInterfaces.CalendarSlotResponse>('api/calendar/v3/slots', {
            params: { productId, slotId, removePastSlots, changeDate }
        });
    }
    public async checkProductPriceLists(productId: string, startDate: Date | string): Promise<ApiResult<boolean>> {
        return this.get<boolean>('api/calendar/v3/CheckSlotPriceList', { params: { productId, startDate } });
    }
    public async getCalendarAvailabilityV3(
        from: string,
        to: string,
        productId: string | null,
        removePastSlots: boolean
    ): Promise<ApiResult<ApiInterfaces.CalendarAvailabilityResponse>> {
        return this.post<ApiInterfaces.CalendarAvailabilityResponse>('api/calendar/v3/availability', {
            from,
            to,
            productId,
            removePastSlots
        });
    }
    public async saveCalendarSlot(
        slot: CalendarSlot,
        disabledDate: string,
        ignoreWarning = false,
        ignoreStopSaleWarning = false,
        moveOrders = false
    ): Promise<ApiResult<ApiInterfaces.CalendarSlotSaveResponse>> {
        return this.post<ApiInterfaces.CalendarSlotSaveResponse>('api/calendar/v3/slot', {
            slot,
            disabledDate: disabledDate || null,
            ignoreStopSaleWarning,
            ignoreWarning,
            moveOrders
        });
    }
    public async revertCalendarSlot(id: string, ignoreWarning = false): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/calendar/v3/slot/revert', {
            params: {
                id,
                ignoreWarning
            }
        });
    }
    public async createCalendarStopSale(
        stopSale: CalendarStopSale,
        ignoreOrderWarning = false,
        ignoreDeleteWarning = false
    ): Promise<ApiResult<ApiInterfaces.CalendarStopSaleSaveResponse>> {
        return this.post<ApiInterfaces.CalendarStopSaleSaveResponse>('api/calendar/v3/slot/stopsale', {
            stopSale,
            ignoreOrderWarning,
            ignoreDeleteWarning
        });
    }
    public async revertCalendarStopSale(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/calendar/v3/slot/stopsale', {
            params: {
                id
            }
        });
    }
    public async deleteCalendarStopSale(id: string): Promise<ApiResult<ApiInterfaces.CalendarAvailabilityResponse>> {
        return this.delete<ApiInterfaces.CalendarAvailabilityResponse>('api/calendar/v3/slot/stopsale', {
            params: { id }
        });
    }
    public async getCalendarEvents(): Promise<ApiResult<ApiInterfaces.CalendarEventsResponse>> {
        return this.get<ApiInterfaces.CalendarEventsResponse>('api/calendar/v3/events');
    }
    public async saveCalendarEvent(event: CalendarEvent): Promise<ApiResult<ApiInterfaces.CalendarEventSaveResponse>> {
        return this.post<ApiInterfaces.CalendarEventSaveResponse>('api/calendar/v3/event', {
            event
        });
    }
    public async deleteCalendarEvent(id: string): Promise<ApiResult<ApiInterfaces.CalendarAvailabilityResponse>> {
        return this.delete<ApiInterfaces.CalendarAvailabilityResponse>('api/calendar/v3/event', {
            params: {
                id
            }
        });
    }
    public async GetOrderRemarks(orderId: string): Promise<ApiResult<ApiInterfaces.GetOrderRemarksResponse>> {
        return this.get<ApiInterfaces.GetOrderRemarksResponse>('api/order/get/remarks', { params: { id: orderId } });
    }
    public async getOrderLeadCustomerName(orderId: string): Promise<ApiResult<string>> {
        return this.get<string>('api/order/lead-customer-name', { params: { orderId } });
    }
    public async SaveOrderRemarks(orderId: string, remarks: string): Promise<ApiResult<ApiInterfaces.SaveOrderRemarksResponse>> {
        return this.post<ApiInterfaces.SaveOrderRemarksResponse>(
            'api/order/save/remarks',
            JSON.stringify({ orderId: orderId, remarks: remarks })
        );
    }
    public async getProducts(filter: ProductFilter | null): Promise<ApiResult<ApiInterfaces.GetProductsResponse>> {
        return this.post<ApiInterfaces.GetProductsResponse>('api/products', JSON.stringify(filter || {}));
    }
    public async getRefProducts(): Promise<ApiResult<ApiInterfaces.GetRefProductsResponse>> {
        return this.get<ApiInterfaces.GetRefProductsResponse>('api/ref/products-list');
    }
    public async getOrderProductDate(
        orderId: string,
        productId: string,
        customerId: string
    ): Promise<ApiResult<ApiInterfaces.GetOrderProductDateResponse>> {
        return this.get<ApiInterfaces.GetOrderProductDateResponse>('api/orderproduct/getDate', {
            params: { orderId, productId, customerId }
        });
    }
    public async SaveNewOrderProductDate(
        req: ApiInterfaces.SaveNewOrderProductDateRequest
    ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/orderproduct/savenewdate', req);
    }
    public async GetDocuments(filter: DocumentsFilter): Promise<ApiResult<ApiInterfaces.GetDocumentsResponse>> {
        return this.post<ApiInterfaces.GetDocumentsResponse>('api/documents', JSON.stringify({ filter }));
    }
    public async GetDocumentOrderNumber(id: string): Promise<ApiResult<ApiInterfaces.GetDocumentOrderNumberResponse>> {
        return this.get<ApiInterfaces.GetDocumentOrderNumberResponse>('api/document/ordernumber', { params: { id } });
    }
    public async removeOrRestoreProductPrice(prodId: string, id: string): Promise<ApiResult<ProductPrice>> {
        return this.post<ProductPrice>(`api/remove-or-restoreprice-list`, JSON.stringify({ productId: prodId, priceListId: id }));
    }
    public async saveProductPrice(productPrice: ProductPrice): Promise<ApiResult<ApiInterfaces.GetProductPriceResponse>> {
        return this.post<ApiInterfaces.GetProductPriceResponse>('api/product-price', JSON.stringify(productPrice));
    }
    public async saveProductAddress(productAddress: SaveProductAddressRequest): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/save-product-address', JSON.stringify(productAddress));
    }
    public async newUserRegistration(form: RegistrationForm): Promise<ApiResult<NewUserRegistrationResponse>> {
        return this.post<NewUserRegistrationResponse>('api/registration', form);
    }
    public async confirmEmail(token: string): Promise<ApiResult<ConfirmEmailResponse>> {
        return this.post<ConfirmEmailResponse>('api/confirm-email', JSON.stringify({ Token: token }));
    }
    public async registrationDetails(details: RegistrationDetails): Promise<ApiResult<NewUserRegistrationResponse>> {
        return this.post<NewUserRegistrationResponse>('api/registration-details', details);
    }
    public async GetDocumentPdfUrl(id: string): Promise<ApiResult<ApiInterfaces.GetDocumentPdfUrlResponse>> {
        return this.get<ApiInterfaces.GetDocumentPdfUrlResponse>('api/document/pdf', { params: { id } });
    }
    public async ActivateOrDisactivate(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>(`api/product/activate-or-disactivate-product/`, { params: { productId: id } });
    }
    public async disactivateOrActivateReceiveAutoOrderNotifications(productId: string): Promise<ApiResult<boolean>> {
        return this.post<boolean>(`api/product/activate-or-disactivate-autoOrderNotifications`, { productId });
    }
    public async ActivateOrDisactivateProductPriceType(productId: string, priceTypeId: string): Promise<ApiResult<boolean>> {
        return this.post<boolean>(`api/product/activate-or-disactivate-product-price-type/`, {
            productId,
            priceTypeId
        });
    }
    public async ActivateOrDisactivateAllowAssignEmployees(productId: string): Promise<ApiResult<boolean>> {
        return this.get<boolean>(`api/product/activate-or-disactivate-allow-assign-employees/${productId}`);
    }
    public async ShareDocument(shareItem: ApiInterfaces.ShareDocumentRequest): Promise<ApiResult<ApiInterfaces.ShareDocumentResponse>> {
        shareItem.timezone = getTimezone();
        return this.post<ApiInterfaces.ShareDocumentResponse>('api/document/share', JSON.stringify(shareItem));
    }
    public async GetDocumentShareData(id: string): Promise<ApiResult<ApiInterfaces.GetDocumentShareDataResponse>> {
        return this.get<ApiInterfaces.GetDocumentShareDataResponse>('api/document/sharedata', { params: { id } });
    }
    public async allowCreateCreditInvoice(orderId: string): Promise<ApiResult<boolean>> {
        return this.get<boolean>('api/allow-create-credit-invoice', { params: { orderId } });
    }
    public async CanCreateProduct(): Promise<ApiResult<boolean>> {
        return this.get<boolean>('api/allow-create-product');
    }
    public async forgotPassword(email: string): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/forgot-password', { Email: email });
    }
    public async resetPassword(form: ResetPasswordForm): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/reset-password', form);
    }
    public async CreateProductCopy(id: string): Promise<ApiResult<Product>> {
        return this.get<Product>('api/copy-product/' + id);
    }
    public async deleteProductImage(id: string, fromWizzard = false): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/product/delete-product-image', { params: { productImageId: id, fromWizzard } });
    }
    public async setMainProductImage(id: string): Promise<ApiResult<boolean>> {
        return this.get<boolean>('api/product/main-product-image', { params: { productImageId: id } });
    }
    public async saveRegions(
        productId: string,
        regionsId: string[],
        fromWizzard: boolean
    ): Promise<ApiResult<ApiInterfaces.ChangeProductRegionsResponse>> {
        return this.post<ApiInterfaces.ChangeProductRegionsResponse>('api/product-regions', {
            ProductId: productId,
            ProductRegions: regionsId,
            FromWizzard: fromWizzard
        });
    }
    public async saveTags(
        productId: string,
        regionsId: string[],
        tagType: string,
        fromWizzard: boolean
    ): Promise<ApiResult<ApiInterfaces.ChangeProductTagsResponse>> {
        return this.post<ApiInterfaces.ChangeProductTagsResponse>('api/product-tags', {
            ProductId: productId,
            ProductTagsId: regionsId,
            ProductTagsType: tagType,
            FromWizzard: fromWizzard
        });
    }
    public async GetProviderSettings(): Promise<ApiResult<ApiInterfaces.GetProviderSettingsResponse>> {
        return this.get<ApiInterfaces.GetProviderSettingsResponse>('api/get-provider-settings');
    }
    public async saveProviderDetails(provider: ProviderSettingsData): Promise<ApiResult<ApiInterfaces.SaveProviderResponse>> {
        return this.post<ApiInterfaces.SaveProviderResponse>('api/providers/save', { provider });
    }
    public async getOrdersNotifications(): Promise<ApiResult<OrdersNotifications>> {
        return this.get<OrdersNotifications>('api/get-order-notifications');
    }
    public async getProductsAlerts(): Promise<ApiResult<ProductAlert[]>> {
        return this.get<ProductAlert[]>('api/product/alerts');
    }
    public async markOrderMessageAsRead(
        orderId: string,
        orderMessageId: string
    ): Promise<ApiResult<ApiInterfaces.MarkOrderMessagesAsReadResponse>> {
        return this.post<ApiInterfaces.MarkOrderMessagesAsReadResponse>('api/order/messages/mark-as-read', {
            OrderId: orderId,
            MessageId: orderMessageId
        });
    }
    public async getProviderAdress(): Promise<ApiResult<ApiInterfaces.GetProviderAddressResponse>> {
        return this.get<ApiInterfaces.GetProviderAddressResponse>('api/get-provider-address');
    }
    public async saveProviderAddressRequest(productAddress: SaveProviderAddressRequest): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/save-provider-address', JSON.stringify(productAddress));
    }
    public async getUsersByFilter(searchText: string): Promise<ApiResult<ApiInterfaces.GetUsersByFilterResponse>> {
        return this.post<ApiInterfaces.GetUsersByFilterResponse>('api/provider/users/search', JSON.stringify({ searchText }));
    }
    public async getUserProfileData(id: string): Promise<ApiResult<ApiInterfaces.ProviderProfile>> {
        return this.get<ApiInterfaces.ProviderProfile>('api/provider/profile/get', { params: { profileId: id } });
    }
    public async saveUserProfileData(profile: ApiInterfaces.ProviderProfile): Promise<ApiResult<ApiInterfaces.ProviderProfile>> {
        return this.post<ApiInterfaces.ProviderProfile>('api/provider/profile/save', { profile });
    }
    public async getProviderProfilesData(searchText: string): Promise<ApiResult<ApiInterfaces.ProviderProfile[]>> {
        return this.get<ApiInterfaces.ProviderProfile[]>('api/provider/profiles/search', { params: { searchText } });
    }
    public async getUserData(id: string): Promise<ApiResult<ApiInterfaces.GetUserDataResponse>> {
        return this.get<ApiInterfaces.GetUserDataResponse>(`api/provider/user/get`, { params: { id } });
    }
    public async getProviderProfiles(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/provider/profiles');
    }
    public async saveUser(user: ApiInterfaces.SettingsProviderUser): Promise<ApiResult<ApiInterfaces.SaveUserResponse>> {
        return this.post<ApiInterfaces.SaveUserResponse>('api/provider/user/save', JSON.stringify({ user }));
    }

    public async UpdateArrivalConfirmation(request: ApiInterfaces.UpdateArrivalConfirmationRequest) {
        return this.post<boolean>('api/order/update-arrival-confirmation', request);
    }

    public async UpdateArrivalConfirmations(request: ApiInterfaces.UpdateArrivalConfirmationsRequest) {
        return this.post<boolean>('api/order/update-arrival-confirmations', request);
    }

    public async exportOrders(filter: OrdersFilter, type: ExportOrdersType): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        return this.post<ApiInterfaces.TaskResponse>('api/export/orders/' + type, filter);
    }

    public async waitExportTask(taskId: string) {
        return this.get<ApiInterfaces.AwaitTaskResponse>('api/export/wait-task', { params: { id: taskId } });
    }
    public async GetProductFAQItem(productId: string, faqId: string) {
        return this.get<ApiInterfaces.ProductFAQ>('api/product/faq/get', { params: { productId, faqId } });
    }
    public async GetProductInfoItem(productId: string, infoId: string) {
        return this.get<ApiInterfaces.ProductMoreInfo>('api/product/moreinfo/get', { params: { productId, infoId } });
    }
    public async SaveFAQTranslations(
        productId: string,
        faqId: string,
        translations: ApiInterfaces.Translation[]
    ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/product/faq-translations/save', { productId, faqId, translations });
    }
    public async SaveMoreInfoTranslations(
        productId: string,
        infoId: string,
        translations: ApiInterfaces.Translation[]
    ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/product/info-translations/save', { productId, infoId, translations });
    }
    public async GetBusinessClients(filter: BusinessClientsFilter): Promise<ApiResult<ApiInterfaces.GetBusinessClientsResponse>> {
        return this.post<ApiInterfaces.GetBusinessClientsResponse>('api/businessClients', JSON.stringify(filter));
    }
    public async GetAllBusinessClients(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/businessClients/all');
    }
    public async GetAllHashedBusinessClients(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/businessClients/hashed/all');
    }
    public async GetBussinesClientsByNewOrder(): Promise<ApiResult<ApiInterfaces.GetBussinesClientsByNewOrderResponse>> {
        return this.get<ApiInterfaces.GetBussinesClientsByNewOrderResponse>('api/businessClients/ByNewOrder');
    }
    public async getBusinessClientDetails(id: number): Promise<ApiResult<ApiInterfaces.GetBusinessClientResponse>> {
        return this.get<ApiInterfaces.GetBusinessClientResponse>('api/businessClient/get', { params: { id } });
    }
    public async saveBusinessClient(businessClient: BusinessClient): Promise<ApiResult<ApiInterfaces.SaveBusinessClientResponse>> {
        return this.post<ApiInterfaces.SaveBusinessClientResponse>('api/businessClient/save', { businessClient });
    }
    public async getBusinessClientTranslation(id: number): Promise<ApiResult<ApiInterfaces.TranslationGeneral[]>> {
        return this.get<ApiInterfaces.TranslationGeneral[]>('api/businessClient/translations/get', {
            params: { id }
        });
    }
    public async updateBusinessClientName(
        id: number,
        translations: ApiInterfaces.TranslationGeneral[]
    ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/businessClient/translations/save', { id, translations });
    }

    public async changeSellingSetting(
        productId: string,
        newValue: boolean,
        field: string
    ): Promise<ApiResult<ApiInterfaces.ChangeSellingSettingResponse>> {
        return this.post<ApiInterfaces.ChangeSellingSettingResponse>(
            'api/product/changeSellingSetting/' + field,
            JSON.stringify({ productId, newValue })
        );
    }
    public async GetSmsHistory(): Promise<ApiResult<ApiInterfaces.GetSmsHistoryResponse>> {
        return this.get<ApiInterfaces.GetSmsHistoryResponse>('api/sms-history/get');
    }
    public async GetChatsAndQuotes(filter: ChatFilter): Promise<ApiResult<ApiInterfaces.GetChatsAndQuotesResponse>> {
        return this.post<ApiInterfaces.GetChatsAndQuotesResponse>('api/chat-messages', filter);
    }
    public async MarkChatAsRead(productId: string, orderQuoteId: number, indexUserId: string): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/chat/mark-as-read', { productId, orderQuoteId, indexUserId });
    }
    public async ProductChatMessages(
        productId: string,
        indexUserId: string,
        orderQuoteId: number,
        fromId: string | undefined
    ): Promise<ApiResult<ChatMessage[]>> {
        return this.post<ChatMessage[]>('api/product-chat-messages', { productId, indexUserId, orderQuoteId, fromId });
    }
    public async SendChatMessage(
        productId: string,
        orderQuoteId: number,
        indexUserId: string,
        message: string
    ): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/product/message', { productId, orderQuoteId, indexUserId, message });
    }
    public async SendChatMessageFiles(
        config: { headers: { 'Content-Type': string } },
        fd: FormData,
        productId: string,
        indexUserId: string
    ): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/product/' + productId + '/' + indexUserId + '/message', fd, config);
    }
    public async GetAgentDetails(id: string): Promise<ApiResult<AgentDetails>> {
        return this.get<AgentDetails>('api/agent-details/' + id);
    }
    public async GetAllProviderProducts(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/all-products-list');
    }
    public async getOrderTicket(orderId: string): Promise<ApiResult<ApiInterfaces.GetOrderTicketResponse>> {
        return this.get<ApiInterfaces.GetOrderTicketResponse>('api/ticket/get', { params: { id: orderId } });
    }
    public async getCoupons(filter: CouponFilter | null): Promise<ApiResult<ApiInterfaces.GetCouponsResponse>> {
        return this.post<ApiInterfaces.GetCouponsResponse>('api/coupons', JSON.stringify(filter || {}));
    }
    public async getCoupon(couponId: string): Promise<ApiResult<Coupon>> {
        return this.get<Coupon>('api/coupon/' + couponId);
    }
    public async getSubCoupons(couponId: string, filter: SubCouponsFilter): Promise<ApiResult<SubCouponsFilterResult>> {
        const url = `api/coupon/${couponId}/get-sub-coupons`;
        return this.post<SubCouponsFilterResult>(url, filter);
    }
    public async exportSubCoupons(couponId: string, filter: SubCouponsFilter): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        const url = `api/coupon/${couponId}/export/sub-coupons`;
        return this.post<ApiInterfaces.TaskResponse>(url, filter);
    }
    public async changeSubCouponStatus(couponId: string, status: SubCouponStatus, subCouponCode: string): Promise<ApiResult<boolean>> {
        const url = `api/coupon/${couponId}/change-sub-coupon-status`;
        return this.post<boolean>(url, {
            Code: subCouponCode,
            Status: status
        });
    }
    public async saveCoupon(coupon: Coupon): Promise<ApiResult<{ id: string }>> {
        return this.post<{ id: string }>('api/coupon/save', JSON.stringify(coupon));
    }
    public async ActivateOrDisactivateBusinessClient(id: number): Promise<ApiResult<boolean>> {
        return this.delete<boolean>(`api/businessClient/activate-or-disactivate-client/`, { params: { businessClientId: id } });
    }

    public async getBusinessOrderProducts(
        filter: BusinessOrderProductsFilter
    ): Promise<ApiResult<ApiInterfaces.GetBusinessOrderProductsResponse>> {
        return this.post<ApiInterfaces.GetBusinessOrderProductsResponse>('api/businessClient/order-products', JSON.stringify({ filter }));
    }

    public async getBusinessAccounts(filter: BusinessAccountingFilter): Promise<ApiResult<ApiInterfaces.GetBusinessAccountsResponse>> {
        return this.post<ApiInterfaces.GetBusinessAccountsResponse>('api/businessClient/accounts', JSON.stringify({ filter }));
    }
    public async getBusinessClientCustomersForInvoice(
        filter: BusinessInvoiceCustomersFilter
    ): Promise<ApiResult<ApiInterfaces.GetBusinessClientCustomersForInvoiceResponse>> {
        return this.post<ApiInterfaces.GetBusinessClientCustomersForInvoiceResponse>(
            'api/businessClient/customersForInvoice',
            JSON.stringify({ filter })
        );
    }
    public async getBusinessClientInfoForInvoice(
        req: ApiInterfaces.GetBusinessClientInfoForInvoiceRequest
    ): Promise<ApiResult<ApiInterfaces.GetBusinessClientInfoForInvoiceResponse>> {
        return this.post<ApiInterfaces.GetBusinessClientInfoForInvoiceResponse>('api/businessClient/infoForInvoice', JSON.stringify(req));
    }
    public async getBusinessInvoicePdf(id: string): Promise<ApiResult<ApiInterfaces.GetBusinessInvoicePdfResponse>> {
        return this.get<ApiInterfaces.GetBusinessInvoicePdfResponse>('api/businessClient/invoicePdf/get', { params: { id } });
    }
    public async getBusinessReceiptPdf(id: string): Promise<ApiResult<ApiInterfaces.GetBusinessReceiptPdfResponse>> {
        return this.get<ApiInterfaces.GetBusinessReceiptPdfResponse>('api/businessClient/receiptPdf/get', { params: { id } });
    }
    public async getInfoForReceipt(id: number, orderId: string): Promise<ApiResult<ApiInterfaces.GetInfoForReceiptResponse>> {
        return this.get<ApiInterfaces.GetInfoForReceiptResponse>('api/receipt/info', {
            params: { id, orderId }
        });
    }
    public async createBusinessInvoice(businessInvoice: BusinessInvoice): Promise<ApiResult<ApiInterfaces.CreateBusinessInvoiceResponse>> {
        return this.post<ApiInterfaces.CreateBusinessInvoiceResponse>('api/create-business-invoice', JSON.stringify(businessInvoice));
    }
    public async getInvoiceDocument(id: string, orderId: string, clientId: number): Promise<ApiResult<BusinessInvoice>> {
        return this.get<BusinessInvoice>('api/invoice/get', { params: { id, orderId, clientId } });
    }
    public async createReceipt(businessReceipt: Receipt): Promise<ApiResult<ApiInterfaces.CreateReceiptResponse>> {
        return this.post<ApiInterfaces.CreateReceiptResponse>('api/create-receipt', JSON.stringify(businessReceipt));
    }
    public async createInvoiceReceipt(invoiceReceipt: InvoiceReceipt): Promise<ApiResult<ApiInterfaces.CreateReceiptResponse>> {
        return this.post<ApiInterfaces.CreateReceiptResponse>('api/create-invoice-receipt', JSON.stringify(invoiceReceipt));
    }
    public async validateReceipt(businessReceipt: Receipt): Promise<ApiResult<ApiInterfaces.ValidateReceiptResponse>> {
        return this.post<ApiInterfaces.ValidateReceiptResponse>('api/validate-receipt', JSON.stringify(businessReceipt));
    }
    public async getBusinessReceipt(id: string, editMode: boolean): Promise<ApiResult<ApiInterfaces.GetBusinessReceiptResponse>> {
        return this.get<ApiInterfaces.GetBusinessReceiptResponse>('api/receipt/get', { params: { id, editMode } });
    }
    public async getInvoiceReceipt(id: string, editMode: boolean): Promise<ApiResult<ApiInterfaces.GetInvoiceReceiptResponse>> {
        return this.get<ApiInterfaces.GetInvoiceReceiptResponse>('api/invoice-receipt/get', { params: { id, editMode } });
    }
    public async getAccountingInfo(): Promise<ApiResult<AccountingInfo>> {
        return this.get<AccountingInfo>('api/provider/accounting-info');
    }
    public async saveAccountingInfo(model: AccountingInfo): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/provider/accounting-info', JSON.stringify(model));
    }
    public async exportDocuments(model: ExportRequest): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        return this.post<ApiInterfaces.TaskResponse>('api/export/accounting', JSON.stringify(model));
    }
    public async waitExportAccountingTask(taskId: string) {
        return this.get<ApiInterfaces.AwaitTaskResponse>(`api/export/accounting/job/${taskId}`);
    }
    public async getExportHistory() {
        return this.get<ApiInterfaces.ExternalAccountingExportRequest[]>('api/export/history');
    }
    public async cancelExportRequest(id: string): Promise<ApiResult<object>> {
        return this.get<object>('api/export/cancel-request/' + id);
    }
    public async cancelExportDocuments(requestId: string, ids: string[]): Promise<ApiResult<object>> {
        return this.post<object>('api/export/cancel-documents', JSON.stringify({ requestId, ids }));
    }
    public async OrderProductDateValidateToChange(request: ApiInterfaces.OrderProductDateValidateToChange) {
        return this.post<boolean>('api/order/product/change', request);
    }
    public async deleteOrderProductCustomer(
        orderId: string,
        orderProductid: string,
        orderProductCustomerId: string
    ): Promise<ApiResult<ApiInterfaces.DeleteOrderProductCustomerResponse>> {
        return this.post<ApiInterfaces.DeleteOrderProductCustomerResponse>('api/order/product/customer/remove', {
            orderId,
            orderProductid,
            orderProductCustomerId
        });
    }

    public async UpdateOrderProductCustomer(
        req: ApiInterfaces.UpdateOrderProductCustomerRequest
    ): Promise<ApiResult<ApiInterfaces.UpdateOrderProductCustomerResponse>> {
        return this.post<ApiInterfaces.UpdateOrderProductCustomerResponse>('api/order/product/customer/update', req);
    }
    public async AddProductInExistOrder(req: ApiInterfaces.AddProductInExistOrderRequest) {
        return this.post<boolean>('api/order/product/add', req);
    }
    public async filterCurrencyRate(
        currencyId: string,
        dateFrom: string,
        dateTo: string
    ): Promise<ApiResult<ApiInterfaces.FilterCurrencyRateResponse>> {
        return this.post<ApiInterfaces.FilterCurrencyRateResponse>(
            'api/provider/currency-rate/filter',
            JSON.stringify({ currencyId, dateFrom, dateTo })
        );
    }
    public async saveProviderCurrencyRate(
        request: ApiInterfaces.SaveProviderCurrencyRateRequest
    ): Promise<ApiResult<ApiInterfaces.SaveProviderCurrencyRateResponse>> {
        return this.post<ApiInterfaces.SaveProviderCurrencyRateResponse>(`api/provider/currency-rate/save`, JSON.stringify(request));
    }
    public async getProviderCurrencyRate(id: string): Promise<ApiResult<ApiInterfaces.GetProviderCurrencyRateResponse>> {
        return this.get<ApiInterfaces.GetProviderCurrencyRateResponse>('api/provider/currency-rate/get', { params: { id } });
    }
    public async activateDeactivateProductNotificationRestriction(
        notificationsRestrictions: ApiInterfaces.NotificationsRestrictions
    ): Promise<ApiResult<boolean>> {
        return this.post<boolean>('api/product-notification-restrictions/save', JSON.stringify(notificationsRestrictions));
    }

    public async cancelModalAlert(modalAlertId: number): Promise<ApiResult<void>> {
        return this.get<void>('api/cancel-modal-alert/' + modalAlertId);
    }

    public async uploadVoucherDocument(
        file: FormData,
        orderId: string,
        docId: string
    ): Promise<ApiResult<ApiInterfaces.VoucherDocumentResponse>> {
        return this.postFormData<ApiInterfaces.VoucherDocumentResponse>(`api/custom-doc/order/${orderId}/document/${docId}`, file);
    }

    public async changeAutoPrintReceiptProviderSetting(
        autoPrintReceipt: boolean
    ): Promise<ApiResult<ApiInterfaces.AutoPrintReceiptProviderSettingResponse>> {
        return this.post<ApiInterfaces.AutoPrintReceiptProviderSettingResponse>(
            'api/provider/autoPrintReceipt',
            JSON.stringify({ autoPrintReceipt })
        );
    }
    public async deleteDocType(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/provider/deleteProviderDocType/' + id);
    }
    public async createDocType(req: ApiInterfaces.ProviderDocumentType) {
        return this.post<ApiInterfaces.ProviderDocumentType>('api/provider/createDocType', req);
    }
    public async GetProviderDocTypes(): Promise<ApiResult<ApiInterfaces.IdName[]>> {
        return this.get<ApiInterfaces.IdName[]>('api/provider/doc-types/get');
    }

    //Employee
    public async getEmployees(filter: EmployeeFilter | null): Promise<ApiResult<ApiInterfaces.GetEmployeesResponse>> {
        return this.post<ApiInterfaces.GetEmployeesResponse>('api/employees', JSON.stringify(filter || {}));
    }
    public async getAssignedEmployees(): Promise<ApiResult<AssignedEmployeesResponse>> {
        return this.get<AssignedEmployeesResponse>('api/assigned-employees');
    }
    public async saveEmployee(employee: Employee): Promise<ApiResult<Employee>> {
        return this.post<Employee>('api/save-employee', JSON.stringify(employee));
    }
    public async getEmployee(id: string): Promise<ApiResult<Employee>> {
        return this.get<Employee>('api/employee/' + id);
    }
    public async checkPastFutureAssignEmployee(id: string): Promise<ApiResult<CheckAssignEmployeeResponse>> {
        return this.get<CheckAssignEmployeeResponse>('api/check-assign-employee/' + id);
    }
    public async deleteEmployee(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/delete-employee/' + id);
    }
    public async getTagsAndProducts(): Promise<ApiResult<ApiInterfaces.GetTagsProducts>> {
        return this.get<ApiInterfaces.GetTagsProducts>('api/tags-products');
    }
    public async createSaveEmployeeTag(tag: IdName): Promise<ApiResult<IdName>> {
        return this.post<IdName>('api/create-save-employee-tag', JSON.stringify(tag));
    }
    public async deleteEmployeeTag(id: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/delete-employee-tag/' + id);
    }
    public async getAllEmployees(): Promise<ApiResult<Employee[]>> {
        return this.get<Employee[]>('api/employees');
    }
    public async loadLeadTypes(): Promise<ApiResult<IdName[]>> {
        return this.get<IdName[]>('api/leadTypes');
    }

    public async getSlotsWithNoEmployee(filter: SlotsWithNoEmployeeFilter): Promise<ApiResult<ApiInterfaces.GetSlotsWithNoEmployee>> {
        return this.post<ApiInterfaces.GetSlotsWithNoEmployee>('api/employee/getSlotsWithNoEmployee', JSON.stringify({ filter }));
    }
    public async assignEmployee(req: ApiInterfaces.AssignEmployeeRequest): Promise<ApiResult<IdName>> {
        return this.post<IdName>('api/employee/assign', JSON.stringify(req));
    }
    public async unassignEmployee(req: ApiInterfaces.AssignEmployeeRequest): Promise<ApiResult<IdName>> {
        return this.post<IdName>('api/employee/unassign', JSON.stringify(req));
    }
    public async exportEmployees(filter: EmployeeFilter): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        return this.post<ApiInterfaces.TaskResponse>('api/export/employees', filter);
    }
    public async getOrderEmployees(orderId: string): Promise<ApiResult<ApiInterfaces.GetOrderEmployeesResponse>> {
        return this.get<ApiInterfaces.GetOrderEmployeesResponse>('api/employees/' + orderId);
    }
    public async getSmsEmailTemplates(filter: MessageTemplateFilter): Promise<ApiResult<GetMessageTemplatesResponse>> {
        return this.post<GetMessageTemplatesResponse>('api/message-templates/list', filter);
    }
    public async getMessageTemplatesLightList(): Promise<ApiResult<GetMessageTemplateLightListResponse>> {
        return this.get<GetMessageTemplateLightListResponse>('api/message-templates/ligth-list');
    }
    public async getMessageTemplateDetails(templateId: string): Promise<ApiResult<MessageTemplate>> {
        return this.get<MessageTemplate>('api/message-template-details/' + templateId);
    }
    public async createOrEditMessageTemplate(req: MessageTemplate): Promise<ApiResult<MessageTemplate>> {
        return this.post<MessageTemplate>('api/message-templates/create-edit', req);
    }
    public async SendSmsEmailByOrderFilter(
        filter: OrdersFilter,
        options: MessageTemplateResource,
        confirmed: boolean
    ): Promise<ApiResult<ApiInterfaces.SendSmsEmailByOrderFilterResponse>> {
        return this.post<ApiInterfaces.SendSmsEmailByOrderFilterResponse>('api/sms-email/order', { filter, options, confirmed });
    }
    public async SendSmsEmailBySlot(
        slotId: string,
        slotStartDate: string,
        options: MessageTemplateResource,
        productId: string,
        confirmed = false
    ): Promise<ApiResult<ApiInterfaces.SendSmsEmailByOrderFilterResponse>> {
        return this.post<ApiInterfaces.SendSmsEmailByOrderFilterResponse>('api/sms-email/slot', {
            slotId,
            productId,
            slotStartDate,
            options,
            confirmed
        });
    }
    public async SaveMessageTemplate(template: MessageTemplate): Promise<ApiResult<MessageTemplate>> {
        return this.post<MessageTemplate>('api/message-templates/create-edit', template);
    }
    public async SaveMessageTemporaryAttachment(
        fd: FormData,
        config: { headers: { 'Content-Type': string } }
    ): Promise<ApiResult<MessageTemplateAttachment>> {
        return this.post<MessageTemplateAttachment>('api/save-message-temporary-attachment', fd, config);
    }
    public async saveAttachmentFile(
        templateId: string,
        fd: FormData,
        config: { headers: { 'Content-Type': string } }
    ): Promise<ApiResult<MessageTemplateAttachment>> {
        return this.post<MessageTemplateAttachment>('api/save-message-template-attachment/' + templateId, fd, config);
    }
    public async GetSmsManagementHistory(filter: SmsManagementFilter): Promise<ApiResult<GetSmsMessagesResponse>> {
        return this.post<GetSmsMessagesResponse>('api/sms/history', filter);
    }
    public async GetEmailManagementHistory(filter: EmailManagementFilter): Promise<ApiResult<GetEmailMessagesResponse>> {
        return this.post<GetEmailMessagesResponse>('api/email/history', filter);
    }
    public async getEmployeesForSlot(slotId: string, startDate: string): Promise<ApiResult<ApiInterfaces.GetEmployeesForSlot>> {
        return this.post<ApiInterfaces.GetEmployeesForSlot>('api/employees/forslot', { slotId, startDate });
    }
    public async ResumeEmailSendNotifications(queueId: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/email/resume', { queueId });
    }
    public async exportEmployeesSlots(filter: ExportEmployeesSlots): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        return this.post<ApiInterfaces.TaskResponse>('api/employee/export-employees-slots', JSON.stringify({ filter }));
    }
    public async ResumeSmsSendNotifications(queueId: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>('api/sms/resume', { queueId });
    }

    public async deleteMessageTempalteAttachmnent(attachmentId: string): Promise<ApiResult<boolean>> {
        return this.delete<boolean>('api/message-template-attachment/remove/' + attachmentId);
    }
    public async checkCanAssignEmployees(orderId: string): Promise<ApiResult<boolean>> {
        return this.get<boolean>('api/can-assign-employees/' + orderId);
    }
    public async PelecardInit(
        amount: number,
        currencyId: string,
        creditCardPaymentType: number,
        payments: number
    ): Promise<ApiResult<ApiInterfaces.PelecardInitResponse>> {
        return this.get('api/pelecard/init', { params: { amount, currencyId, creditCardPaymentType, payments } });
    }

    public async getNextPurchaseCoupons(): Promise<ApiResult<Coupon[]>> {
        return this.get<Coupon[]>('api/coupons/next-purchase');
    }
    public async CreateBulkInvoices(filter: OrdersFilter, issueDate: string): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        return this.post<ApiInterfaces.TaskResponse>('api/bulk-invoices/create', JSON.stringify({ filter, issueDate }));
    }
    public async WaitBulkInvoices(taskId: string) {
        return this.get<ApiInterfaces.AwaitBulkInvoicesResponse>('api/bulk-invoices/wait', { params: { id: taskId } });
    }
    public async GetBulkInvoicesHistoryInfo(id: string): Promise<ApiResult<ApiInterfaces.GetBulkInvoicesHistoryInfo>> {
        return this.get<ApiInterfaces.GetBulkInvoicesHistoryInfo>('api/bulk-invoices-history/get', { params: { id } });
    }
    public async GetBulkInvoiceHistories(pageIndex: number): Promise<ApiResult<ApiInterfaces.GetBulkInvoiceHistories>> {
        return this.get<ApiInterfaces.GetBulkInvoiceHistories>('api/provider/bulk-invoice-histories/get', { params: { pageIndex } });
    }
    public async CancelExistReceipt(id: string, refundAmount: number): Promise<ApiResult<CancelReceiptResponse>> {
        return this.post<CancelReceiptResponse>('api/receipt/cancel', { id, refundAmount });
    }
    public async downloadDocument(id: string): Promise<ApiResult<Blob>> {
        return this.get<Blob>('api/document/download', { responseType: 'blob', params: { id } });
    }
    public async GetPaymentsAccountTransactions(
        filter: PaymentsAccountTransactionsFilter
    ): Promise<ApiResult<ApiInterfaces.GetPaymentsAccountTransactionsResponse>> {
        return this.post<ApiInterfaces.GetPaymentsAccountTransactionsResponse>(
            'api/payments-account-transactions/get',
            JSON.stringify(filter)
        );
    }
    public async exportPaymentsAccountTransactions(
        filter: PaymentsAccountTransactionsFilter
    ): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
        return this.post<ApiInterfaces.TaskResponse>('api/export/payments-account-transactions', filter);
    }

    public async externalCodesImport(fd: FormData) {
        return await this.postFormData<ApiInterfaces.ExternalCodesImportResponse>('api/external-codes/import', fd);
    }

    public async externalCodesImportPreview(fd: FormData) {
        return await this.postFormData<ApiInterfaces.ExternalCodesImportPreviewResponse>('api/external-codes/import/preview', fd);
    }

    public async externalCodesSearch(productId: string, searchTerm: string | null) {
        const url = '/api/external-codes/search';
        return await this.get<ApiInterfaces.ExternalCodesSearchResponse>(url, { params: { productId: productId, searchTerm: searchTerm } });
    }

    public async externalCodesDeleteAll(productId: string) {
        const url = '/api/external-codes/delete-all';
        return await this.post(url, { ProductId: productId });
    }
    public async ExistCouponsForThisOrder(
        orderId: string,
        businessClientId: number
    ): Promise<ApiResult<ApiInterfaces.ExistCouponsForThisOrderResponse>> {
        return this.post<ApiInterfaces.ExistCouponsForThisOrderResponse>('api/coupon/ExistCouponsForThisOrder', {
            orderId,
            businessClientId
        });
    }
    public async CalculateForUpdateOrderProductCustomer(
        req: ApiInterfaces.CalculateForUpdateOrderProductCustomerRequest
    ): Promise<ApiResult<ApiInterfaces.CalculateForUpdateOrderProductCustomerResponse>> {
        return this.post<ApiInterfaces.CalculateForUpdateOrderProductCustomerResponse>('api/order/product/customer/calculate', req);
    }
    public async saveCrmCustomer(data: ApiInterfaces.SaveCrmCustomerRequest): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerResponse>('api/crm-customer/save', data);
    }
    public async getCrmCustomer(id: string): Promise<ApiResult<ApiInterfaces.GetCrmCustomerResponse>> {
        return this.get<ApiInterfaces.GetCrmCustomerResponse>('api/crm-customer/get', { params: { id } });
    }
    public async getCrmCustomerShortInfo(id: string): Promise<ApiResult<ApiInterfaces.GetCrmCustomerShortInfoResponse>> {
        return this.get<ApiInterfaces.GetCrmCustomerShortInfoResponse>('api/crm-customer/short/get', { params: { id } });
    }
    public async getCrmCustomerList(filter: CrmCustomerListFilter): Promise<ApiResult<ApiInterfaces.GetCrmCustomerListResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomerListResponse>('api/crm-customer/list', filter);
    }
    public async saveCrmCustomerReminder(
        reminder: CrmCustomerReminderSaveRequest
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerReminderResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerReminderResponse>('api/crm-customer/reminder/save', reminder);
    }
    public async deleteCrmCustomerReminder(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/reminder/delete/' + id);
    }
    public async getRelativeInterval(alertDateTime: string): Promise<ApiResult<ApiInterfaces.GetRelativeIntervalResponse>> {
        return this.get<ApiInterfaces.GetRelativeIntervalResponse>('api/relative-interval/get', { params: { alertDateTime } });
    }
    public async snoozeReminder(
        crmCustomerId: string,
        reminderId: string,
        snoozeMinutes: number
    ): Promise<ApiResult<ApiInterfaces.SnoozeReminderResponse>> {
        return this.post<ApiInterfaces.SnoozeReminderResponse>(`api/crm-customer/reminder/snooze`, {
            crmCustomerId,
            reminderId,
            snoozeMinutes
        });
    }
    public async dismissAllUserReminders(reminderIds: string[]): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.post<ApiInterfaces.ApiResponseBase>(`api/crm-customer/reminder/dismiss-all`, { reminderIds });
    }
    public async deleteCrmCustomer(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/delete/' + id);
    }
    public async SendCrmCustomerMessage(request: ApiInterfaces.SendCrmCustomerMessageApiRequest) {
        const formData = new FormData();
        formData.append('message', request.message);
        request.attachments.forEach(file => {
            formData.append('attachments', file);
        });
        return this.post<ApiInterfaces.ApiResponseBase>(`api/crm-customer/messages/send`, formData, {
            params: {
                crmCustomerId: request.crmCustomerId,
                quickReplyId: request.quickReplyId,
                crmCustomerLeadId: request.crmCustomerLeadId
            }
        });
    }
    public async GetCrmCustomerMessages(crmCustomerId: string, type: string, fromId = '', direction = PaginationDirection.Forward) {
        return this.post<ApiInterfaces.GetCrmCustomerMessagesResponse>('api/crm-customer/messages/get', {
            crmCustomerId,
            type,
            fromId,
            direction
        });
    }
    public async GetCrmCustomerLeadMessages(
        crmCustomerId: string,
        crmCustomerLeadId: string,
        type: string,
        fromId = '',
        direction = PaginationDirection.Forward
    ) {
        return this.post<ApiInterfaces.GetCrmCustomerMessagesResponse>('api/crm-customer/lead/messages/get', {
            crmCustomerId,
            crmCustomerLeadId,
            type,
            fromId,
            direction
        });
    }
    public async markCrmCustomerMessageAsRead(
        crmCustomerId: string,
        messageId: string
    ): Promise<ApiResult<ApiInterfaces.MarkOrderMessagesAsReadResponse>> {
        return this.post<ApiInterfaces.MarkOrderMessagesAsReadResponse>('api/crm-customer/messages/mark-as-read', {
            crmCustomerId,
            messageId
        });
    }
    public async markCrmCustomerLeadMessageAsRead(
        crmCustomerId: string,
        crmCustomerLeadId: string,
        messageId: string
    ): Promise<ApiResult<ApiInterfaces.MarkOrderMessagesAsReadResponse>> {
        return this.post<ApiInterfaces.MarkOrderMessagesAsReadResponse>('api/crm-customer/lead/messages/mark-as-read', {
            crmCustomerId,
            crmCustomerLeadId,
            messageId
        });
    }
    public async saveQuickReplyMessage(quickReply: QuickReplyMessageEditModel): Promise<ApiResult<QuickReplyMessageViewModel>> {
        return this.post<QuickReplyMessageViewModel>('api/crm-customer/quick-reply-message/save', quickReply);
    }
    public async getQuickReplyMessages(): Promise<ApiResult<ApiInterfaces.GetQuickReplyMessagesResponse>> {
        return this.get<ApiInterfaces.GetQuickReplyMessagesResponse>('api/crm-customer/quick-reply-messages/get-all');
    }
    public async deleteQuickReplyMessage(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/quick-reply-message/delete/' + id);
    }
    public async saveTimingMessage(
        req: ApiInterfaces.SaveTimingMessageRequest
    ): Promise<ApiResult<ApiInterfaces.SaveTimingMessageResponse>> {
        return this.post<ApiInterfaces.SaveTimingMessageResponse>('api/crm-customer/timing-message/save', req);
    }
    public async deleteTimingChatMessage(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/timing-message/delete/' + id);
    }
    public async downloadCrmCustomerMessageAttachment(id: string): Promise<ApiResult<Blob>> {
        return this.get<Blob>(`api/crm-customer/attachment/get/${id}`, { responseType: 'blob', params: { id } });
    }
    public async SendContractFormToClient(request: SendContractFormRequest) {
        const url = `api/order/send-contract-form`;
        return await this.post(url, request);
    }
    public async deleteCoupon(couponId: string): Promise<ApiResult<boolean>> {
        const url = `api/coupon/delete`;
        return this.delete<boolean>(url, {
            params: {
                couponId: couponId
            }
        });
    }
    public async getCrmCustomerHistory(
        crmCustomerId: string,
        lastHistoryId: string | null = null
    ): Promise<ApiResult<ApiInterfaces.GetCrmCustomerHistoryResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomerHistoryResponse>('api/crm-customer/history/get', { crmCustomerId, lastHistoryId });
    }
    public async getCrmCustomerOrders(
        crmCustomerId: string,
        lastOrderId: string | null = null
    ): Promise<ApiResult<ApiInterfaces.GetCrmCustomerOrdersResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomerOrdersResponse>('api/crm-customer/orders/get', { crmCustomerId, lastOrderId });
    }
    public async getCrmCustomerLeads(
        crmCustomerId: string,
        lastLeadId: string | null = null
    ): Promise<ApiResult<ApiInterfaces.GetCrmCustomerLeadsResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomerLeadsResponse>('api/crm-customer/leads/get', { crmCustomerId, lastLeadId });
    }
    public async getCrmCustomerReminders(
        crmCustomerId: string,
        filter: CrmCustomerReminderFilter,
        lastReminderId: string | null = null
    ): Promise<ApiResult<ApiInterfaces.GetCrmCustomerRemindersResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomerRemindersResponse>('api/crm-customer/reminders/get', {
            crmCustomerId,
            filter,
            lastReminderId
        });
    }
    public async getCrmCustomerActivityTypes(): Promise<ApiResult<ApiInterfaces.GetCrmCustomerActivityTypes>> {
        return this.get<ApiInterfaces.GetCrmCustomerActivityTypes>('api/crm-customer/activity-types/get');
    }
    public async saveCrmCustomerActivityType(
        id: string | null,
        active: boolean,
        name: string
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerActivityTypeResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerActivityTypeResponse>('api/crm-customer/activity-type/save', {
            id,
            active,
            name
        });
    }
    public async deleteCrmCustomerActivityType(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/activity-type/delete/' + id);
    }
    public async getCrmCustomerActivityLocations(): Promise<ApiResult<ApiInterfaces.GetCrmCustomerActivityLocations>> {
        return this.get<ApiInterfaces.GetCrmCustomerActivityLocations>('api/crm-customer/activity-locations/get');
    }
    public async saveCrmCustomerActivityLocation(
        id: string | null,
        active: boolean,
        name: string
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerActivityLocationResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerActivityLocationResponse>('api/crm-customer/activity-location/save', {
            id,
            active,
            name
        });
    }
    public async deleteCrmCustomerActivityLocation(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/activity-location/delete/' + id);
    }
    public async saveCrmCustomerActivity(
        data: ApiInterfaces.SaveCrmCustomerActivityRequest
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerActivityResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerActivityResponse>('api/crm-customer/activity/save', data);
    }
    public async deleteCrmCustomerActivity(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/activity/delete/' + id);
    }
    public async getCrmCustomerLeadList(
        filter: CrmCustomerLeadListFilter
    ): Promise<ApiResult<ApiInterfaces.GetCrmCustomerLeadListResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomerLeadListResponse>('api/crm-customer/lead/list', filter);
    }
    public async GetCrmCustomerLeadTypes(): Promise<ApiResult<ApiInterfaces.GetCrmCustomerLeadTypes>> {
        return this.get<ApiInterfaces.GetCrmCustomerLeadTypes>('api/crm-customer/lead-types/get');
    }
    public async saveCrmCustomerLeadType(
        id: string | null,
        active: boolean,
        name: string
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerLeadTypeResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerLeadTypeResponse>('api/crm-customer/lead-type/save', {
            id,
            active,
            name
        });
    }
    public async deleteCrmCustomerLeadType(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/lead-type/delete/' + id);
    }

    public async GetCrmCustomerLeadSources(): Promise<ApiResult<ApiInterfaces.GetCrmCustomerLeadSources>> {
        return this.get<ApiInterfaces.GetCrmCustomerLeadSources>('api/crm-customer/lead-sources/get');
    }
    public async saveCrmCustomerLeadSource(
        id: string | null,
        active: boolean,
        name: string
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerLeadSourceResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerLeadSourceResponse>('api/crm-customer/lead-source/save', {
            id,
            active,
            name
        });
    }
    public async deleteCrmCustomerLeadSource(id: string): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
        return this.delete<ApiInterfaces.ApiResponseBase>('api/crm-customer/lead-source/delete/' + id);
    }
    public async saveCrmCustomerLead(
        data: ApiInterfaces.SaveCrmCustomerLeadRequest
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerLeadResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerLeadResponse>('api/crm-customer/lead/save', data);
    }
    public async getCrmCustomerLead(id: string): Promise<ApiResult<ApiInterfaces.GetCrmCustomerLeadResponse>> {
        return this.get<ApiInterfaces.GetCrmCustomerLeadResponse>('api/crm-customer/lead/get', { params: { id } });
    }
    public async saveCrmCustomerCreateLeadCreate(
        crmCustomer: ApiInterfaces.SaveCrmCustomerRequest,
        crmCustomerLead: ApiInterfaces.SaveCrmCustomerLeadRequest
    ): Promise<ApiResult<ApiInterfaces.SaveCrmCustomerCreateLeadCreateResponse>> {
        return this.post<ApiInterfaces.SaveCrmCustomerCreateLeadCreateResponse>('api/crm-customer/create/lead/create', {
            crmCustomer,
            crmCustomerLead
        });
    }

    public async getCrmCustomersForLeadCreation(
        searchText: string
    ): Promise<ApiResult<ApiInterfaces.GetCrmCustomersForLeadCreationResponse>> {
        return this.post<ApiInterfaces.GetCrmCustomersForLeadCreationResponse>('api/crm-customer/for-lead-creation/search', { searchText });
    }
}

export const api = new Api();
